import { useRoundware } from '../hooks';
import React, { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import InfoIcon from '@mui/icons-material/Info';
import calhumLogo from '../assets/calhum2.png';
import cslLogo from '../assets/CSL_logo.png';

const InfoPopup = () => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<IconButton onClick={handleClickOpen}>
				<InfoIcon />
			</IconButton>
			<Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>One Square Mile, 10,000 Voices</DialogTitle>
				<DialogContent dividers>
					{/*<Typography variant={'h6'} gutterBottom>
						Roundware is:
					</Typography>*/}
					<Typography variant={'h6'} gutterBottom>
						<strong>A location-based, participatory audio documentary about
						place, memory, and injustice</strong>
					</Typography>
					<Typography gutterBottom>
						In 1942, the U.S. government forced 120,000 men, women, and
						children to leave their homes, and incarcerated them in harsh,
						remote camps. More than 10,000 Japanese Americans, many from
						Los Angeles, were held at Manzanar during World War II.
						<strong> One Square Mile, 10,000 Voices</strong> is an interactive documentary
						experience at Manzanar National Historic Site and in Little Tokyo,
						exploring the legacy of that experience.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Listen:
					</Typography>
					<Typography gutterBottom>
						With the mobile app and a pair of headphones, listen to audio recordings
						geolocated across the landscape. Each story is attached to a specific
						location-what you hear depends on where you walk. You can look at the
						map to see where recordings are located, or simply put your phone in
						your pocket and wander around.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Speak:
					</Typography>
					<Typography gutterBottom>
						As you wander, you can also record your own stories, sounds, and
						observations to add to the project. Together, we are collectively
						creating an evolving soundscape, in conversation with the past and
						the future. Learn more at&nbsp;
						<Link
							href='https://onesquaremile.fm'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							onesquaremile.fm
						</Link>.
						<br />
					</Typography>
					<Divider />
					<Typography gutterBottom>
						<br />
						This project is produced by&nbsp;
						<Link
							href='https://halseyburgund.com'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							Halsey Burgund
						</Link> and&nbsp;
						<Link
							href='http://www.sue-ding.com/'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							Sue Ding
						</Link>
						, in
						partnership with the Manzanar Committee and the U.S. National
						Park Service. This project is made possible with support from
						the&nbsp;
						<Link
							href='https://www.library.ca.gov/grants/civil-liberties/'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							California Civil Liberties Public Education Program
						</Link> and&nbsp;
						<Link href='https://calhum.org/' style={{color: "#fe75fe"}} target="_blank">
							California Humanities
						</Link>,
						a non-profit partner of the
						National Endowment for the Humanities.
					</Typography>
					<img id="logo" src={cslLogo} style={{width: "45%"}} />
					<img id="logo" src={calhumLogo} style={{width: "45%"}} />
					<Divider />
					<br />
					{/*<Divider />
					<Typography variant={'h6'} gutterBottom>
						What is this?
					</Typography>
					<Typography gutterBottom>
						This sound-map project aims to get you out
						and about, exploring the areas around the waterways of Providence RI.
						You will be able to listen to and contribute to a growing body of
						place-based audio addressing the histories, sounds and stories
						connected to this city’s water. We call this an “augmented reality
						audio map” since the app allows you to augment what you hear at a
						particular location in Providence with whatever pre-recorded audio
						that the participating artists, or other visitors, have left there
						for you to find.
					</Typography>
					<Divider />
					<Typography variant={'h6'} gutterBottom>
						<br />
						How can you experience this?
					</Typography>
					<ol>
						<li>
							Install this app on your phone (a web version may be available
							at a later date for those on non-iOS phones - check&nbsp;
							<Link href='https://pvdwaterways.org/sound-journeys/'>our website</Link>
							&nbsp;for updates and an mp3 playlist for those unable to use the app)
						</li>
						<li>Connect and put on your headphones.</li>
						<li>
							Enable "location services - when using the app" for this app on
							your phone when prompted, then select "always" when prompted again
							(if your location doesn’t update when you move around and you
							aren't prompted, you can do this manually as shown here).
						</li>
						<li>
							On the home screen, tap “Listen”, and use the map that comes up
							to find areas with clusters of site specific sounds in them
							(the little circles) - you may already be in one!
						</li>
						<li>
							When on-site in an area covered by Tidal Resonance (See here or
							the paper program if available for the full coverage map), you
							should automatically hear the general ambient soundscapes we've
							composed begin to play automatically. When you approach a
							site-specific sound, it should begin playing as well, and will
							continue to do so until it ends. No other site-specific sound
							will play until this one is done - so don't be surprised if you
							walk past other circles and don't hear them immediately.
							You're welcome to tap the "skip ahead" button to stop listening
							to a site-specific sound or move to another adjacent one.
						</li>
						<li>
							At any point, you can pause all sounds by tapping the "pause"
							button, or start them again by tapping the "play"
							button at the bottom of the screen.
						</li>
					</ol>
					<Divider />
					<Typography variant={'h6'} gutterBottom>
						<br />
						Add your own sounds!
					</Typography>
					<Typography gutterBottom>
						Whenever so moved, you may tap the "Speak" button and record a short
						(up to 2 minute long) message, poem, story, or sound, listen to
						your recording, and if you approve, choose where to place it on
						the map. This will then be added to the publicly available audio
						map. You can even accompany it with some text and a photo before
						submitting, although these won't appear at the moment. Please note
						that project directors will be regularly reviewing submissions to
						ensure that they adhere to our content guidelines: don't be mean,
						hateful, racist, sexist, or explicit, and you'll be ok.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						<br />
						What is this a part of, and where can I learn more?
					</Typography>
					<Typography gutterBottom>
						<em>Tidal Resonance</em> is part of the <em>Providence Waterways</em> project
						by <em>Doors Open RI</em>. Providence Waterways is a
						multimedia, collaborative storytelling project that invites
						residents to dive into our relationship with water. Through a
						combination of online and onsite experiences, Providence Waterways
						extends an invitation into place-based artistic and historical
						interventions that explore the stories of Providence’s water.
					</Typography>
					<Divider />
					<Typography gutterBottom>
						<br />
						Learn more about the project, explore rich story-maps, and find the
						Tidal Resonance live performance art program and full artist and
						producer credits at&nbsp;
						<Link href='https://pvdwaterways.org'>pvdwaterways.org</Link>.
					</Typography>
					<Divider />*/}
					<Typography gutterBottom>
						<br />
						One Square Mile is powered by Roundware: an open, flexible,
						distributed framework which collects, stores, organizes and
						re-presents augmented reality audio content. Learn more at&nbsp;
						<Link
							href='https://roundware.org'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							roundware.org
						</Link>.
					</Typography>
					<Typography gutterBottom>
						Website, app and print design by&nbsp;
						<Link
							href='http://shardurv.com'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							Sharanya Durvasula
						</Link>.
					</Typography>
					<Typography gutterBottom>
						<br />
						<Link
							href='https://onesquaremile.fm/privacy.html'
							style={{color: "#fe75fe"}}
							target="_blank"
						>
							PRIVACY NOTICE
						</Link>
					</Typography>

					{/*<a href="./listen">
            <img id="map" src={assetMapGraphic} style={{width: "100%"}} />
          </a>
          <hr />*/}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='secondary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default InfoPopup;
